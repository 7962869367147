import React from "react"
import { BlogLayout } from "../layouts"
import { withTranslation } from 'react-i18next';
import { SEO, HeroPlain, BlogReferenssit } from "../components";
//import { Link } from "gatsby"

const Referenssit = ({posts, title, hero, background="/images/blog_hero.jpg", meta, children, t, i18n, bg }) => {
    
   /* let language = "en";
    if (i18n.language && i18n.language !== "en") {
      language = i18n.language;
    }*/
    
    return (
      <BlogLayout title={title}>
        <SEO title={title} meta={meta}/>
        <HeroPlain background={background}>
          <div className="container max_width970">
            {hero}
          </div>
        </HeroPlain>
        <div className="content_11 padding_bottom50">
    	    <div className="container bg_white padding_left50 padding_right50 padding_top50 min_height500">
      	  <BlogReferenssit posts={posts} bg='white'/>

            	   
      	 {/*   <section className="font18 light dark_blue text right50">
                {posts.map(({ node }) => {
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                      <div className="" key={node.fields.slug}>
                        <h5>
                          <Link className="blue" to={node.fields.path}>
                            {title}
                          </Link>
                        </h5>
                        <small>{node.frontmatter.date}</small>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: node.frontmatter.description || node.excerpt,
                          }}
                        />
                      </div>
                    )
                  })}
            </section>*/}
            {children}
          </div>
        </div>
      </BlogLayout>
    )
}

export default withTranslation()(Referenssit)