import React from "react"
import Referenssit from "../../templates/referenssit"
import { withTranslation } from 'react-i18next';
import { graphql } from "gatsby"
import {
  References,
  Reference
} from '../../components';

export default withTranslation()(({ pageContext, data, t }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Referenssit title={'Referenssitarinat | Duuers mukana yrittäjien arjessa'} 
      posts={edges}
      background="/images/blog_category2A.jpg"
      meta={[
      {name:'description', content: 'Duuers on helpottanut lukuisien yrittäjien arkea ja auttanut heitä menestymään tarjoustyökalunsa avulla. Tutustu referenssitarinoihimme!'}
       ]}
       hero={<div>
        <h1 className={"font_second semibold font40"}>Referenssitarinat – Duuers auttaa asiakkaitaan menestymään</h1>
        <p className="font20 padding_top20 opacity9 text">
          Jokainen yrittäjä tietää, miltä tarjouksen teon kanssa painiskelu tuntuu, ja ennen kaikkea: kuinka paljon työaikaa se viekään! Onneksi Duuers on ratkaissut asian - lue, miten asiakkaidemme arki onhelpottunut avullamme!
        </p>
        <References>
    			<Reference  name="Iiro Hänninen" title="Myyntipäällikkö ja kumppanisi korteissa" company="Korttilinna" image="/images/references/iiro_500x500.png">
            Tarjousten laatu on parantunut huomattavasti ja asiakkaat ovat antaneet pointsit palvelusta! Takaisin vanhaan ei ole paluuta!
    			</Reference>
          <Reference  name="Ari Järvinen" title="Energia-alan yrittäjä" company="Lamit.fi" image="/images/references/ari_500x500.png">
            Duuers osoittautui ylivoimaisesti helppokäyttöisimmäksi tarjoustyökaluksi, koska se keskittyy olennaiseen.
    			</Reference>
    			<Reference  name="Mikko Heiskanen" title="Myynnintuottaja ja yrittäjä" company="Myynnintuottajat Group" image="/images/references/mikko_500x500.png">
            Olen suositellut Duuersia koko verkostolleni! Duuersin tarjoustyökalun avulla olemme tehostaneet tarjouksen lähettämisen prosessia huomattavasti.
    			</Reference>
    			<Reference name="Reeta Laamo" title="Luova yrittäjä" company="Red & Blue" image="/images/references/reeta_laamo_500x500.png">
            Duuersin avulla pystyn muokkaamaan lähettämääni tarjousta jo ennenkuin asiakas on avannut tarjouksen. Aloitan päiväni aina katsomalla viimeisimmät tapahtumat Duuersissa.
          </Reference>
          <Reference name="Marko Eskola" title="Myynti-insinööri ja yrittäjä" company="Arctic Team" image="/images/references/marko_500x500.png">
            Yksi Duuersin parhaita ominaisuuksia on, että pystyn seuraamaan miten asiakasprosessi etenee ja pystyn viemään tarjouksen maaliin yhdessä asiakkaan kanssa.
          </Reference>
    			<Reference name="Jani Saarinen" title="Henkilövuokraus yrittäjä" company="JAX" image="/images/references/jax_500x500.png">
            Duuersin paras ominaisuus on, että tarjouspohja on aina valmiina ja helposti muokattavissa - missä ja milloin vain.
          </Reference>
          <Reference  name="Tuukka Ranta-Pere" title="Moderni tilitoimistoyrittäjä" company="Dreamfaktori" image="/images/references/tuukka_500x500.png">
            Tarjousten laatiminen on ollut omassa työssä nihkeintä. Eipä ole enää! 
    			</Reference>
          <Reference  name="Henri Nuutinen" title="Autokouluyrittäjä" company="Ajomesta Nuutinen" image="/images/references/henri_500x500.png">
            Duuersin luoman huikean tarjouspohjan avulla pääsimme nopeasti alkuun. Nyt tarjoustyöskentely ja kommunikointi asiakkaan kanssa sujuu paremmin kuin koskaan aikaisemmin! 
    			</Reference>
          <Reference name="Salme Kortelainen" title="HR-palvelu yrittäjä" company="Calmen HR" image="/images/references/salme_500x500.png">
            Duuersin avulla tarjousten laatiminen on vaivatonta. Lopputuloksena on ulkomuodoltaan selkeä ja helppolukuinen tarjous.
          </Reference>
    			<Reference  name="Heikki Holmström" title="Remonttialan yrittäjä" company="Munkkiniemen Remonttiapu" image="/images/references/heikki_500x500.png">
            Onneksi lähdin mukaan, koska ohjelma oli loppujen lopuksi tosi helppo ottaa käyttöön. Nyt teen tarjoukset 10 minuutissa, kun aikaisemmin niihin meni kaksi tuntia!
    			</Reference>
          <Reference  name="Jani Teräväinen" title="Rakennusalan yrittäjä" company="Työmaapalvelut Express" image="/images/references/jani_500x500.png">
            Duuers on tärkein työkaluni, jonka avulla tarjoustyöskentelyni on tehostunut huomattavasti ja koko tiimini on aina ajantasalla tehdyistä ja hyväksytyistä tarjouksista. 
    			</Reference>
      </References>
      </div>}>
    </Referenssit>
  )
})
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: ["referenssitarinat"] }, date: { ne: null } } }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            meta {
              name
              content
            }
            link {
              rel
              href
              hreflang
            }
            hero
          }
        }
      }
    }
  }
`
/* export const pageQuery = graphql `
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: ["referenssitarinat"] }, date: { ne: null } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            path
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  }
`*/
